/* InterestPopup.module.scss */
.popupOverlay {
    position: fixed;
    top: 5vw;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100;
  }
  
  .popupContainer {
    background: #fff;
    width: 80%;
    max-width: 600px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .closeBtn {
    position: absolute;
    top: 0.5vw;
    right: 1.5vw;
    background: transparent;
    border: none;
    font-size: 8vw;
    color: #FFFFFF;
    cursor: pointer;
    z-index: 2;
  }
  
  .headerImage {
    width: 100%;
    height: 40%;    // 이미지의 높이는 유지
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;  // 이미지 비율 유지
    }
  }
  
  .formContainer {
    width: 94%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  // 중앙 정렬
  
    h2 {
      text-align: center;
      margin-bottom: 20px;
      color: #333;
      font-size: 1.5rem;
    }
  
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  
    .formGroup {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      align-items: center;  // 수평 중앙 정렬
      width: 100%;
      justify-content: center;
  
      label {
        font-size: 20px;
        color: #555;
        margin-bottom: 5px;
        text-align: center;
        span {
          color: red;
          margin-left: 3px;
        }
      }
  
      input {
        padding: 12px;
        font-size: 16px;
        border: 1px solid #ddd;
        border-radius: 4px;
        transition: border-color 0.3s;
        text-align: center;
        width: 70%;      // 입력창이 부모 너비에 맞춰 넓게 채워짐
        max-width: 400px; // 최대 너비 설정
        margin: 0 auto;   // 중앙 정렬
        display: block;   // block 요소로 만들어서 자동으로 중앙 정렬
  
        &:focus {
          outline: none;
          border-color: #891a1d;
        }
      }
    }
  
    .submitBtn {
      padding: 12px;
      font-size: 16px;
      background-color: #891a1d;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #891a1d;
      }
    }
  }
  
  .successMessage {
    font-size: 0.9vw; /* PC 버전에서의 기본 폰트 크기 */
    color: #000000; /* 기본 글자 색상 */
  }
  
  /* 모바일 버전 스타일 */
  @media screen and (max-width: 768px) {
    .successMessage {
      font-size: 2.3vw; /* 모바일에서의 폰트 크기 */
      color: #000000; /* 모바일에서의 글자 색상 (예: 빨간색) */
    }
  }
  